@charset "UTF-8";
// Breakpoints - Min
$screen-bs-min:				0;
$screen-xs-min:				360px;
$screen-sm-min:				480px;
$screen-md-min:				768px;
$screen-lg-min:				1024px;
$screen-xl-min:				1280px;
$screen-xxl-min:			1440px;
$screen-hd-min:				1920px;
// Breakpoints - Max
$screen-bs-max:				$screen-xs-min - 1; // 319px
$screen-xs-max:				$screen-sm-min - 1; // 479px
$screen-sm-max:				$screen-md-min - 1; // 767px
$screen-md-max:				$screen-lg-min - 1; // 1023px
$screen-lg-max:				$screen-xl-min - 1; // 1279px
$screen-xl-max:				$screen-xxl-min - 1; // 1439px
$screen-xxl-max:			$screen-hd-min - 1; // 1919px

// Width to .re-central
$re-central-width-bs: 		100%;
$re-central-width-xs: 		100%;
$re-central-width-sm: 		94%;
$re-central-width-md: 		94%;
$re-central-width-lg: 		($screen-lg-min * 0.875);
$re-central-width-xl: 		($screen-xl-min * 0.875);
$re-central-width-xxl: 		($screen-xxl-min * 0.875);
$re-central-width-hd: 		($screen-hd-min * 0.85);

// Typography
$font-title:                'Rokkitt', sans-serif;
$font-subtitle:				'Lexend Deca', sans-serif;
$font-copy:					'Racing Sans One', cursive;
$font-body:					'Montserrat', sans-serif;
$font-number:				'Roboto', sans-serif;
$font-icons:				'resiliente', sans-serif;

// Font Weight
$font-thin:					100;
$font-x-light:				200;
$font-light:				300;
$font-regular:				400;
$font-medium:				500;
$font-semibold:				600;
$font-bold:					700;
$font-x-bold:				800;
$font-black:				900;

// Color Palette
$c-primary:					#28324e;
$c-primary-l10:				lighten($c-primary,10);
$c-primary-d10:				darken($c-primary,10);
$c-secondary:				#fd9c00;
$c-secondary-l10:			lighten($c-secondary,10);
$c-secondary-d10:			darken($c-secondary,10);
$c-text:					#535256;
$c-text-l10:				lighten($c-text,10);
$c-text-l20:				lighten($c-text,20);
$c-text-l30:				lighten($c-text,30);
$c-text-l40:				lighten($c-text,40);
$c-text-d10:				darken($c-text,10);
$c-lightgray:				#eeeeee;
$c-lightgray-l-1:			lighten($c-lightgray,2); //f3f3f3
$c-lightgray-l-2:			lighten($c-lightgray,4); //f8f8f8
$c-lightgray-d10:			darken($c-lightgray,10); //dcdcdc
$c-lightgray-d30:			darken($c-lightgray,30); //a4a4a4
$c-dark:					#2c3137;
$c-link-default:			#1b96e4;
$c-invalido:				#dd1c47;
// Gray Scale
$c-dark-gray:                       #333333;
$c-taupe-gray:                      #4a4a4a;
$c-dim-gray:                        #666666;
$c-dusty-gray:                      #acacac;
$c-pastel-gray:                     #dddddd;
$c-light-gray:                      #f2f2f2;

// z-index
$z-brand:					40;
$z-header:					20;
$z-header-top-bar:			20;
$z-header-middle-bar:		40;
$z-nav-mobile:				30;
$z-nav-desktop:				30;
$z-main:					1;
$z-footer:					15;
$z-sidebar:					25;
$z-searcher:				25;

// Header Height
$header-height-bs:			50px;
$header-height-md:			60px;
$header-height-lg:			85px;
// Header Height - Fixed
$header-height-lg-fixed:	100px;
$header-height-xxl-fixed:	105px;
// Header - Difference to element positions
$header-difference:			15px;

// Gutter
$re-gutter:					15px;
// Animation Time
$animate-time:				0.2s;
// Paths
$font-path:                         'https://ivan.visionario.mx/';
$image-path:                        'https://ivan.visionario.mx/';

// Breakpoints - Map
$breakpoints-min: (
    bs:                             $screen-bs-min,
    xs:                             $screen-xs-min,
    sm:                             $screen-sm-min,
    md:                             $screen-md-min,
    lg:                             $screen-lg-min,
    xl:                             $screen-xl-min,
    xxl:                            $screen-xxl-min,
    hd:                             $screen-hd-min
);
$breakpoints-max: (
    bs:                             $screen-bs-max,
    xs:                             $screen-xs-max,
    sm:                             $screen-sm-max,
    md:                             $screen-md-max,
    lg:                             $screen-lg-max,
    xl:                             $screen-xl-max,
    xxl:                            $screen-xxl-max,
    hd:                             10000px
);
$breakpoints: (
    bs:                             (min: $screen-bs-min, max: $screen-bs-max),
    xs:                             (min: $screen-xs-min, max: $screen-xs-max),
    sm:                             (min: $screen-sm-min, max: $screen-sm-max),
    md:                             (min: $screen-md-min, max: $screen-md-max),
    lg:                             (min: $screen-lg-min, max: $screen-lg-max),
    xl:                             (min: $screen-xl-min, max: $screen-xl-max),
    xxl:                            (min: $screen-xxl-min, max: $screen-xxl-max),
    hd:                             (min: $screen-hd-min, max: 10000px)
);

// Color Palette - Map
$color-palette-map: (
	'primary':				(color: $c-primary, font: white,),
	'secondary':			(color: $c-secondary, font: white,),
	'dark':					(color: $c-dark, font: $c-secondary,),
	'pastel-gray':          (color: $c-pastel-gray, font: white,),
	'light-gray':          (color: $c-light-gray, font: white,),
	'white':				(color: white, font: black,),
	'black':				(color: black, font: white,)
);

// Color Palette - List
$color-palette-list:
	primary					$c-primary 		$c-primary-d10,
	secondary				$c-secondary 	$c-secondary-d10,
	white					white 			white,
	black					black 			black
;

// Font-weight - List
$font-weight-list: (
	(thin, 100),
	(x-light, 200),
	(light, 300),
	(regular, 400),
	(medium, 500),
	(semibold, 600),
	(bold, 700),
	(x-bold, 800),
	(black, 900)
);