/*--------------------Product List--------------------*/
// Search | View Mode | Sort Results
.products-search-result{
	position: relative;
	margin-bottom: 15px;
	@media screen and (min-width: $screen-md-min){
		text-align: right;
	}
	h1{
		margin-bottom: 50px;
		text-align: left;
		@media screen and (min-width: $screen-sm-min){
			margin-bottom: 15px;
		}
		@media screen and (min-width: $screen-lg-min){
			position: absolute;
			margin-bottom: 0;
		}
	}
}
.product-view-mode,
.product-list-sort-wrapper,
.product-sort{
	display: inline-block;
	vertical-align: middle;
	padding-right: 15px;
	&:last-child{
		padding-right: 0;
	}
}
// Trigger para cambiar vista del listado
.product-view-mode{
	i{
		margin-right: 0.2em;
		color: $c-lightgray-d30;
		cursor: pointer;
		&:hover{
			color: $c-primary;
		}
		&.view-mode-active{
			color: $c-secondary;
		}
	}
}
// Ordenamiento del listado
.product-list-sort-wrapper{
	@media screen and (max-width: $screen-sm-max){
		display: block;
		clear: both;
		@include padding-v(15,0);
	}
}
.product-sort{
	*{
		@include font-size(0.8);
	}
}