/*--------------------General [Re]sponsive | Iván Luna [Re]--------------------*/
header,
nav,
main,
footer,
.re-central,
.re-middle,
.re-cien{
	width: 100%;
	min-width: 240px;
}
.re-central,
.re-middle,
.re-cien{
	@include padding-h;
	@include horizontal-center-auto;
}
.re-central{
	@include re-central-width;
}
.re-middle{
	width: 88%;
}