/*-----Items individuales-----*/
.product-item-wrapper{
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    @include font-size(1);
}
.product-item{
    border: 2px solid white;
    text-align: center;
    background-color: white;
    // Image
    &-figure{
        .contenedor-img{
            height: auto;
            padding-bottom: 75%;
            border-radius: 5px;
            display: block;
            position: relative;
            overflow: hidden;
            img.img-centrada{
                width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                transition: max-height $animate-time/2, top $animate-time, opacity $animate-time;
            }
        }
        .product-item-image-hover{
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            background-color: rgba($c-primary,0);
            opacity: 0;
        }
    }
    // Info [Name, Description]
    &-caption{
        @include padding-h(10,10);
        z-index: 15;
        @include font-size(0.85);
        transition: opacity 0.5s 0.1s;
        @media screen and (min-width: $screen-md-min){
            @include font-size;
        }
        @media screen and (min-width: $screen-lg-min){
            @include font-size(1.2);
        }
    }
    &-name{
        @include padding-h;
        @include padding-v(0,5);
        @include margin-v(12,5);
        font-family: $font-subtitle;
        @include font-size(1.2);
        font-weight: $font-medium;
        color: black;
        &::after{
            content: '';
            width: 1em;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: $c-secondary;
            transform: translateX(-50%);
        }
        a{
            color: inherit;
        }
    }
    &-kind{
        @include padding-v(0,2);
        @include margin-v(0,4);
        font-family: $font-body;
        font-weight: $font-medium;
        font-size: rem(15);
        color: $c-taupe-gray;

    }
    &-summary{
        font-size: rem(13.5);
        color: $c-dim-gray;
        white-space: pre-wrap;
    }
    // Action
    &-action{
        margin-top: 1rem;
        transition: opacity 0.5s 0.1s;
        @include mediaFrom(lg){
            margin-top: 35%;
            @include horizontal-center;
            top: 0;
            z-index: 15;
            opacity: 0;
        }
        [class*="button-"]{
            @include font-size(0.85);
            @include mediaFrom(lg){
                display: block;
                margin-bottom: 1em;
                @include margin-h(5);
            }
        }
        .link{
            display: none;
            font-size: rem(12);
            @include mediaFrom(lg){
                display: block;
            }
        }
    }
}
.product-item:hover{
    .product-item-figure{
        .product-item-image-hover{
            opacity: 1;
        }
    }
    .product-item-quick-view{
        display: block;
        @media screen and (max-width: $screen-sm-max){
            display: none;
        }
    }
}

// Vista en cuadrícula
.product-list.grid-view{
    .product-item-wrapper{
        float: left;
        // @include padding-h;
    }
    .product-item{
        &:hover{
            &:before{
                opacity: 0.85;
            }
        }
        &:before{
            border-radius: 5px;
            content: '';
            padding-bottom: 75%;
            padding-bottom: calc(75% - 2px);
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            z-index: 10;
            background-color: black;
            opacity: 0;
            transition: opacity 0.2s;
        }
        &-agency{
            margin-top: 5px;
            font-size: 0.7em;
            color: $c-dusty-gray;
            &-label{
            }
            &-name{
                font-weight: $font-medium;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }

    .product-item:hover{
        .product-item-caption{
            opacity: 1;
        }
        .product-item-action{
            opacity: 1;
        }
    }
    // Size Grid
    &.grid-small{
        .product-item-wrapper{
            width: 100%;
            @media screen and (min-width: $screen-sm-min){
                width: 50%;
            }
            @media screen and (min-width: $screen-lg-min){
                width: 33.33%;
            }
            @media screen and (min-width: $screen-xxl-min){
                width: 25%;
            }
            &:nth-child(0){
                width: 50%;
            }
        }
    }
}