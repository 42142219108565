.ProjectDetail{
    @include mediaFrom(md){
        @include flexbox($ai: center);
    }
    position: relative;
    &-Gallery{
        @include mediaFrom(xl){
            width: 45%;
        }
        img{
            width: 100%;
            display: block;
        }
    }
    &-Info{
        width: 90%;
        padding: 2em;
        background-color: rgba(white,0.95);
        transform: translate(5%,-25%);
        @include mediaFrom(md){
            width: 50%;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-25%, -50%);
        }
        @include mediaFrom(xl){
            width: 40%;
            padding: 2.5em;
            transform: translate(-50%, -50%);
        }
        @include mediaFrom(hd){
            width: 30%;
            padding: 3em;
        }
        &::before,
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $c-pastel-gray;
            @include mediaFrom(md){
                top: 0;
            }
        }
        &::before{
            width: 25px;
            height: 1px;
        }
        &::after{
            width: 1px;
            height: 25px;
        }
    }
    &-Name{
        em{
            display: block;
            font-weight: inherit;
            line-height: 1.1;
        }
    }
    &-Summary{
        color: $c-dark-gray;
    }
}