/*--------------------Site Header - Internal page--------------------*/
.body--internal-page .site-header{
	// background-color: $c-secondary;
}

/*--------------------Page Main to Home page, Internal page--------------------*/
.page-main--home-page{
	margin-top: $header-height-bs;
	@media screen and (min-width: $screen-md-min){
		margin-top: $header-height-md;
	}
	@media screen and (min-width: $screen-lg-min){
		margin-top: $header-height-lg;
	}
}
.page-main--internal-page{
	margin-top: $header-height-bs;
	@media screen and (min-width: $screen-md-min){
		margin-top: $header-height-md;
	}
	@media screen and (min-width: $screen-lg-min){
		margin-top: $header-height-lg;
	}
}

/*--------------------Header to Internal page, Product list, Product detail--------------------*/
.internal-page-header{
	@include padding-v(15);
	.bread-crumbs{
		// Here
	}
	h1{
		@include margin-v(15,0);
	}
}
.product-list-page-header{
	@include padding-v(15);
	.bread-crumbs{
		// Here
	}
	h1{
		@include margin-v(15,0);
	}
}
.product-detail-page-header{
	@include padding-v(10,0);
	.bread-crumbs{
		// Here
	}
	h1{
		@include margin-v(15,0);
	}
}

/*--------------------Tag Titles--------------------*/
.tag-title--level-1{
	display: block;
	font-family: $font-subtitle;
    @include font-size(2.2);
    font-weight: $font-bold;
	line-height: 0.75;
    color: black;
	@media screen and (min-width: $screen-md-min){
		@include font-size(2.5);
	}
	@media screen and (min-width: $screen-xxl-min){
		@include font-size(3);
	}
	&:after{
		content: '';
		width: 20%;
		height: 1px;
		display: block;
		margin-top: 0.5em;
		background-color: $c-pastel-gray;
    }
    small{
        font-size: 0.45em;
        font-weight: $font-medium;
        color: $c-secondary;
    }
}
.tag-title--level-2{
	display: block;
	font-family: $font-subtitle;
    @include font-size(2.2);
    font-weight: $font-bold;
	line-height: 1;
    letter-spacing: 0.05em;
    color: black;
	@media screen and (min-width: $screen-md-min){
		@include font-size(2.5);
	}
	@media screen and (min-width: $screen-xxl-min){
		@include font-size(2.5);
	}
}
.tag-title--level-3{
    @include margin-v(0.5,0.5,em);
	display: block;
	font-family: $font-subtitle;
    @include font-size(1.5);
    font-weight: $font-bold;
	line-height: 1;
    letter-spacing: 0.05em;
    color: black;
}
.tag-legend{
	display: block;
	font-family: $font-subtitle;
	@include font-size(1.75);
	font-weight: $font-light;
	line-height: 1.3;
	@media screen and (min-width: $screen-xxl-min){
		@include font-size(2.5);
	}
}

/*--------------------Font Gradient--------------------*/
.font-gradient{
	color: $c-primary;
	background: -webkit-gradient(linear, left top, right bottom, from($c-primary), to($c-secondary));
	// -webkit-background: linear-gradient(to right, $c-web, $c-ecommerce, $c-marketing, $c-social);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}