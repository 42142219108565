.CharacterList{
    display: flex;
    flex-wrap: wrap;
}
.CharacterCard{
    width: 50%;
    padding: 1px;
    margin-bottom: 1em;
    text-align: center;
    @media screen and (min-width: 480px) {
        width: 33.33%;
    }
    @media screen and (min-width: 1024px) {
        width: 25%;
    }
    @media screen and (min-width: 1366px) {
        width: 20%;
    }
    &-Image{
        width: 100%;
        display: block;
    }
    &-Name{
        margin-bottom: 0;
        font-size: 1.25rem;
    }
    &-Specie{
        font-size: 0.85rem;
    }
}