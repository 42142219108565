/*--------------------General Layout - Brand--------------------*/
h1.brand-wrapper{
	position: static;
	@include margin-v(0);
	font-size: 0;
}
a.brand{
	z-index: $z-brand;
	font-family: $font-title;
    @include font-size(1.2);
    line-height: 0.6;
	text-decoration: none;
	color: $c-primary;
	// background: transparent url("../images/layout/project-brand.png") no-repeat 0 0;
	transition: all 0s;

	@media only screen and (min-width: $screen-md-min)	{
	    @include font-size(1.75);

    }
    em{
        display: block;
        text-transform: uppercase;
        span{
            color: $c-secondary;
        }
    }
    small{
        font-family: $font-subtitle;
        font-size: 0.475em;
        color: $c-dusty-gray;
    }
}