/*--------------------General Layout - Main--------------------*/
main{
    body > &{
        min-height: 425px;
        overflow: hidden;
        position: relative;
        top: 0;
        left: 0;
        z-index: $z-main;
        background-color: rgba(white,0.2);
        > .re-central:after,
        > .re-cien > .re-central:after{
            content: '';
            display: block;
            clear: both;
        }
    }
}
