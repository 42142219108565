/*--------------------Go to Top--------------------*/
.go-to-top {
	display: inline-block;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	padding: 10px;
	cursor: pointer;
	background-color: $c-secondary;
	@include opacity(0.5);
	transition: opacity $animate-time;
	&:hover{
		@include opacity(1);
	}
	> i { 
		margin-right: 0;
		font-size: 1.5em;
		font-weight: $font-light;
		color: white;
		&:hover {
		}
	}
}