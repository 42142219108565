/*--------------------Forms--------------------*/
form{
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	color: $c-text;
}
fieldset{
	padding: 1% 4% 2% 4%;
	border: 1px dotted $c-lightgray-d30;
	border-radius: 10px;
	& + fieldset{
		margin-top: $re-gutter;
	}
}
legend{
	width: auto;
	padding: 0 3%;
	@include font-size(1.3);
	font-weight: $font-semibold;
	color: $c-text-d10;
	text-transform: uppercase;
}
// Control wrappers
form, 
fieldset{
	// > div,
	.control-wrapper{
		width: 100%;
		position: relative;
		clear: both;
		margin-bottom: 5px;
		@media only screen and (min-width: $screen-xs-min){
			&.float-left, 
			&.float-right{
				width: 48%;
				clear: none;
			}
		}
	}
	.current-data{
		// border-bottom: 1px solid $c-gray;
		label{
			// @include font-size(0.8);
		}
	}
	.captcha{
		background: none;
		> input[type="text"]{
			width: 50%;
			float: right;
			clear: none;
			border: 1px solid $c-primary;
			margin-top: 20px;
		}
		> img{
			width: 45%;
			display: block;
			float: left;
		}
		> a{
			position: absolute;
			top: 0;
			right: 65%;
			cursor: pointer;
		}
	}
	.send{
		overflow: hidden;
		clear: both;
		margin-bottom: 0;
		margin-top: 10px;
	}
}
// Labels & Controls
label{
	// display: block;
	// float: left;
	border: none;
	font-weight: $font-medium;
	color: $c-text-d10;
	line-height: 25px;
	span{
		height: 25px;
		margin-left: 2px;
		font-family: $font-number;
		font-weight: $font-light;
		color: $c-primary;
	}
}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
input[type="password"],
input[type="file"],
input[type="number"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="time"],
input[type="week"],
input[type="month"],
input[type="color"],
select,
textarea{
	width: 100%;
	height: 30px;
	display: block;
	clear: both;
	padding: 2px 5px 2px 5px;
	border: 1px solid $c-lightgray-d10;
	@include font-size;
	font-weight: $font-regular;
	color: inherit;
	background: white;
	@include placeholder($c-text-l20);
	transition: all .5s ease;
	&:focus{
		// border: 1px solid $c-primary;
	}
	&.touched:invalid{
		border: 1px dashed $c-invalido;
		box-shadow: none;
	}
}
input[type="file"]{
	height: 32px;
}
input[type="checkbox"],
input[type="radio"]{
	// float: left;
	margin: 5px 5px 0 0;
}
textarea{
	height: 80px;
}

// Alerts
.alert{
	overflow: hidden;
	@include padding-v(10);
	@include margin-v(5);
	@include font-size;
	color: darken($c-text,10);
	text-align: center;
	border-width: 1px;
	border-style: solid;
	&.info{
		color: lighten($c-text,10);
		border-color: lighten($c-text,10);
		background-color: rgba(lighten($c-text,10),0.05);
	}
	&.success{
		color: $c-primary;
		border-color: $c-primary;
		background-color: rgba($c-primary,0.05);
	}
	&.error,
	&.danger{
		color: $c-invalido;
		border-color: $c-invalido;
		background-color: rgba($c-invalido,0.05);
	}
	span{
		color: darken($c-text,10);
		font-weight: $font-medium;
	}
}

// Validate
.error{
	display: block;
	color: $c-invalido;
}
label.error{
	@include font-size(0.7);
	font-weight: $font-regular;
	color: $c-invalido;
}
input.error,
textarea.error{
	border-color: lighten($c-invalido,25) !important;
}

// General searcher
.searcher-wrapper{
	width: 100%;
	position: fixed;
	top: $header-height-bs;
	left: 0;
	z-index: $z-searcher;
	@include padding-h;
	background-color: rgba(black,0.8);
	@media screen and (max-width: $screen-md-max){
		display: none;
		@include padding-v(15);
	}
	@media screen and (min-width: $screen-md-min){
		top: $header-height-md;
	}
	@media screen and (min-width: $screen-lg-min){
		width: 130px;
		display: block !important;
		position: static;
		@include padding-h(0);
		top: auto;
		bottom: 15px;
		right: 50%;
		background-color: transparent;
	}
	@media screen and (min-width: $screen-xl-min){
		width: 200px;
	}
	@media screen and (min-width: $screen-xxl-min){
		width: 230px;
	}
}
.search-form{
	position: relative;
	@include horizontal-center-auto;
	color: white;
	@include re-central-width-until-md;
	@media screen and (min-width: $screen-lg-min){
		@include padding-h(0);
	}
	.field-wrapper{
		margin-bottom: 0;
	}
	input[type="text"]{
		height: 20px;
		padding-left: 10px;
		padding-right: 2.25em;
		border-color: inherit;
		font-size: inherit;
		line-height: 20px;
		background-color: transparent;
		@include placeholder(white);
	}
	button{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 5px;
		@media screen and (min-width: $screen-lg-min){
			right: 10px;
		}
		i{
			font-size: 1em;
			margin-right: 0;
		}
	}
}
#generalSearcherTrigger{
	position: absolute;
	top: 12px;
	@include font-size(1.5);
	line-height: 1;
	color: $c-secondary;
	cursor: pointer;
	transform: translate(-50px,0);
	@include re-central-right-until-md;

	@media screen and (min-width: $screen-md-min){
		top: 50px;
		transform: translate(-40px,0);
	}
	@media screen and (min-width: $screen-lg-min){
		display: none;
	}
	&:before{
		top: 0;
	}
}

// Contact form
.contact-form{
	.control-wrapper{
		margin-bottom: 15px;
	}
}