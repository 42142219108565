/*--------------------Mixins - Iván Luna [Re]--------------------*/
/// @name PXtoEM
$browser-context: 16;
@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}
/// @name PXtoREM
@function rem($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1rem;
}

/* ----------Width for container .re-central---------- */
@mixin re-central-width{
    width: 100%;
    // Mobile
    @media screen and (min-width: $screen-bs-min) and (max-width: $screen-bs-max)   {width: $re-central-width-bs;}
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)   {width: $re-central-width-xs;}
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)   {width: $re-central-width-sm;}
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)   {width: $re-central-width-md;}
    // Desktop
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {width: $re-central-width-lg;}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {width: $re-central-width-xl;}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {width: $re-central-width-xxl;}
    @media screen and (min-width: $screen-hd-min)                                   {width: $re-central-width-hd;}
}
@mixin re-central-width-until-md($important: null){
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)   {width: $re-central-width-md $important;}
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)   {width: $re-central-width-sm $important;}
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)   {width: $re-central-width-xs $important;}
    @media screen and (max-width: $screen-bs-max)                                   {width: $re-central-width-bs $important;}
}
@mixin re-central-width-from-lg($important: null){
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {width: $re-central-width-lg $important;}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {width: $re-central-width-xl $important;}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {width: $re-central-width-xxl $important;}
    @media screen and (min-width: $screen-hd-min)                                   {width: $re-central-width-hd $important;}
}

/* ----------Aligment respect .re-central---------- */
@mixin re-central-left-until-md($important: null){
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)   {left: calc((100% - #{$re-central-width-md}) / 2 + 15px) $important;}
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)   {left: calc((100% - #{$re-central-width-sm}) / 2 + 15px) $important;}
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)   {left: calc((100% - #{$re-central-width-xs}) / 2 + 15px) $important;}
    @media screen and (max-width: $screen-bs-max)                                   {left: calc((100% - #{$re-central-width-bs}) / 2 + 15px) $important;}
}
@mixin re-central-left-from-lg($has-bp-lg: false, $important: null){
    @if $has-bp-lg == false {
        @media screen and (min-width: $screen-lg-min) {
            left: 50% $important;
            transform: translateX(15px);
        }
    }
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {margin-left: -($re-central-width-lg / 2);}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {margin-left: -($re-central-width-xl / 2);}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {margin-left: -($re-central-width-xxl / 2);}
    @media screen and (min-width: $screen-hd-min)                                   {margin-left: -($re-central-width-hd / 2);}
}
@mixin re-central-left-from-lg--fluid($important: null){
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {left: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {left: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {left: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-hd-min)                                   {left: (100% - $re-central-width-md ) / 2 $important;}
}
@mixin re-central-right-until-md($important: null, $space: false){
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)   {right: calc((100% - #{$re-central-width-md}) / 2 + 15px) $important;}
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)   {right: calc((100% - #{$re-central-width-sm}) / 2 + 15px) $important;}
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)   {right: calc((100% - #{$re-central-width-xs}) / 2 + 15px) $important;}
    @media screen and (max-width: $screen-bs-max)                                   {right: calc((100% - #{$re-central-width-bs}) / 2 + 15px) $important;}
}
@mixin re-central-right-from-lg($has-bp-lg: false, $important: null){
    @if $has-bp-lg == false {
        @media screen and (min-width: $screen-lg-min) {
            right: 50% $important;
            transform: translateX(-15px);
        }
    }
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {margin-right: -($re-central-width-lg / 2);}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {margin-right: -($re-central-width-xl / 2);}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {margin-right: -($re-central-width-xxl / 2);}
    @media screen and (min-width: $screen-hd-min)                                   {margin-right: -($re-central-width-hd / 2);}
}
@mixin re-central-right-from-lg--fluid($important: null){
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)   {right: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max)   {right: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {right: (100% - $re-central-width-md ) / 2 $important;}
    @media screen and (min-width: $screen-hd-min)                                   {right: (100% - $re-central-width-md ) / 2 $important;}
}

/* ----------Flexbox---------- */
// Container
@mixin flex-direction($fd: row) {
    @if $fd != null and $fd != row{
        flex-direction: $fd;
    }
}
@mixin flex-wrap($fw: nowrap) {
    @if $fw != null and $fw != nowrap{
        flex-wrap: $fw;
    }
}
@mixin flex-flow($fd: row, $fw: nowrap){
    @if $fd != null and $fd != row{
        @if $fw != null and $fw != nowrap{
            flex-flow: $fd $fw;
        }
        @else{
            @include flex-direction($fd);
            @include flex-wrap($fw);
        }
    }
    @else{
        @include flex-direction($fd);
        @include flex-wrap($fw);
    }
}
@mixin justify-content($jc: flex-start){
    @if $jc != null and $jc != flex-start{
        justify-content: $jc;
    }
}
@mixin align-items($ai: stretch){
    @if $ai != null and $ai != stretch{
        align-items: $ai;
    }
}
@mixin align-content($ac: stretch){
    @if $ac != null and $ac != stretch{
        align-content: $ac;
    }
}
// Items
@mixin order($o: 0){
    @if $o != null and $o != 0{
        order: $o;
    }
}
@mixin flex-grow($fg: 0){
    @if $fg != null and $fg != 0{
        flex-grow: $fg;
    }
}
@mixin flex-shrink($fs: 1){
    @if $fs != null and $fs != 1{
        flex-shrink: $fs;
    }
}
@mixin flex-basis($fb: auto){
    @if $fb != null and $fb != auto{
        flex-basis: $fb;
    }
}
@mixin flex($fg: 0, $fs: 1, $fb: auto){
    @if $fg != null and $fg != 0{
        @if $fs != null and $fs != 1{
            @if $fb != null and $fb != auto{
                flex: $fg $fs $fb;
            }
            @else{
                @include flex-grow($fg);
                @include flex-shrink($fs);
                @include flex-basis($fb);
            }
        }
        @else{
            @include flex-grow($fg);
            @include flex-shrink($fs);
            @include flex-basis($fb);
        }
    }
    @else{
        @include flex-grow($fg);
        @include flex-shrink($fs);
        @include flex-basis($fb);
    }
}
@mixin align-self($as: auto){
    @if $as != null and $as != auto{
        align-self: $as;
    }
}
/// @name Flexbox
/// @author Iván López Luna [Re]
/// @access public
/// @since Resilient 3.3.0
@mixin flexbox($d: flex, $fd: null, $fw: null, $jc: null, $ai: null, $ac: null){
    display: $d;
    @include flex-flow($fd, $fw);
    @include justify-content($jc);
    @include align-items($ai);
    @include align-content($ac);
}
/// @name FlexItem
/// @author Iván López Luna [Re]
/// @access public
/// @since Resilient 3.3.0
@mixin flex-item($o: null, $fg: null, $fs: null, $fb: null, $as: null){
    @include order($o);
    @include flex($fg, $fs, $fb);
    @include align-self($as);
}

@mixin flex-width($flex-width: 100%){
    width: $flex-width;
    flex-basis: $flex-width;
}

// Box table
@mixin box-table{
	display: table;
	> div{
		display: table-cell;
		float: none;
		vertical-align: middle;
	}
}

// Padding vertical
@mixin padding-v($top: 15, $bottom: $top, $unit: px){
	padding-top: $top+$unit;
	padding-bottom: $bottom+$unit;
}

// Padding horizontal
@mixin padding-h($left: 15, $right: $left, $unit: px){
	padding-left: $left+$unit;
	padding-right: $right+$unit;
}

// Border vertical
@mixin border-v($width: 1, $type: solid, $color: $c-lightgray){
	border-top: $width+px $type $color;
	border-bottom: $width+px $type $color;
}

// Border horizontal
@mixin border-h($width: 1, $type: solid, $color: $c-lightgray){
	border-left: $width+px $type $color;
	border-right: $width+px $type $color;
}

// Margin vertical
@mixin margin-v($top: 15, $bottom: $top, $unit: px){
	margin-top: $top+$unit;
	margin-bottom: $bottom+$unit;
}

// Margin horizontal
@mixin margin-h($left: 15, $right: $left, $unit: px){
	margin-left: $left+$unit;
	margin-right: $right+$unit;
}

// Centrar verticalmente y horizontalmente
@mixin absolute-center{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: inherit;
}

// Centrar verticalmente con top y transform
@mixin vertical-center{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

// Centrar horizontalmente con left y transform
@mixin horizontal-center{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

/* ----------Helpers---------- */
/// @name SizeProportion
/// @author Iván López Luna [Re]
/// @access public
/// @param {number} [$rW=1]
/// @param {number} [$rH=$rW]
/// @param {number} [$dW=null]
/// @param {number} [$dH=$dW]
/// @param {number} [$unit=1em]
/// @since Resilient 3.3.0
@mixin sizeProportion($rW: 1, $rH: $rW, $dW: null, $dH: $dW, $unit: 1em){
    @if $rW == $rH {
        @if $dW != null {
            width: $dW * $unit;
            height: $dW * $unit;
        }
        @else {
            width: $dH * $unit;
            height: $dH * $unit;
        }
    }
    @else {
        @if $dW != null {
            width: $dW * $unit;
            height: ($dW * $rH / $rW) * $unit;
        }
        @else {
            width: ($dH * $rW / $rH) * $unit;
            height: $dH * $unit;
        }
    }
}

// Centrar horizontalmente con margin auto
@mixin horizontal-center-auto{
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix(){
	&:after{
		content: '';
		display: table;
		clear: both;
	}
}

// Font size to pt
@mixin font-size($fz: 1){
	font-size: ($fz*12)+pt;
	font-size: $fz+rem;
}

// Font size to rem
@mixin font-size-pt($fz: 10){
	font-size: $fz+pt;
	font-size: ($fz/12)+rem;
}

// Opacidad
@mixin opacity($opacidad) {
  opacity: $opacidad;
  filter: alpha(opacity=#{$opacidad * 100});
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	&::-moz-placeholder           { color: $color; }
	&:-moz-placeholder            { color: $color; }
	&:-ms-input-placeholder       { color: $color; }
	&::-webkit-input-placeholder  { color: $color; }
}

/* ----------Media Queries---------- */
@function breakpointMinExist($breakpoint){
    @return map-has-key($breakpoints-min, $breakpoint);
}
@function breakpointMaxExist($breakpoint){
    @return map-has-key($breakpoints-max, $breakpoint);
}
@function breakpointMinVal($breakpoint){
    @return map-get($breakpoints-min, $breakpoint);
}
@function breakpointMaxVal($breakpoint){
    @return map-get($breakpoints-max, $breakpoint);
}
/// @name BreakpointIsValid
@function breakpointIsValid($breakpoint){
    @return type_of($breakpoint) == number and unit($breakpoint) == px or unit($breakpoint) == em or unit($breakpoint) == rem;
};
/// @name MediaForm
/// @author Iván López Luna [Re]
/// @access public
/// @param {string, number} $breakpoint-min
/// @since Resilient 3.3.0
@mixin mediaFrom($breakpoint-min){
    @if breakpointMinExist($breakpoint-min){
        @media screen and (min-width: breakpointMinVal($breakpoint-min)) {
            @content;
        }
    }
    @else if breakpointIsValid($breakpoint-min){
        @media screen and (min-width: $breakpoint-min){
            @content;
        }
    }
    @else{
        @error 'No value could be retrieved from "#{$breakpoint-min}". It is either not defined in the map, or it is an invalid number of px, em, or rem.';
    }
}
/// @name MediaTo
/// @author Iván López Luna [Re]
/// @access public
/// @param {string, number} $breakpoint-max
/// @since Resilient 3.3.0
@mixin mediaTo($breakpoint-max){
    @if breakpointMaxExist($breakpoint-max){
        @media screen and (max-width: breakpointMaxVal($breakpoint-max)) {
            @content;
        }
    }
    @else if breakpointIsValid($breakpoint-max){
        @media screen and (max-width: $breakpoint-max){
            @content;
        }
    }
    @else{
        @error 'No value could be retrieved from "#{$breakpoint-max}". It is either not defined in the map, or it is an invalid number of px, em, or rem.';
    }
}
/// @name MediaBetween
/// @author Iván López Luna [Re]
/// @access public
/// @param {string, number} $breakpoint-min
/// @param {string, number} $breakpoint-max
/// @since Resilient 3.3.0
@mixin mediaBetween($breakpoint-min, $breakpoint-max){
    @if breakpointMinExist($breakpoint-min) and breakpointMaxExist($breakpoint-max) {
        @media screen and (min-width: breakpointMinVal($breakpoint-min)) and (max-width: breakpointMaxVal($breakpoint-max)) {
            @content;
        }
    }
    @else if breakpointMinExist($breakpoint-min) and breakpointIsValid($breakpoint-max){
        @media screen and (min-width: breakpointMinVal($breakpoint-min)) and (max-width: $breakpoint-max) {
            @content;
        }
    }
    @else if breakpointIsValid($breakpoint-min) and breakpointMaxExist($breakpoint-max){
        @media screen and (min-width: $breakpoint-min) and (max-width: breakpointMaxVal($breakpoint-max)) {
            @content;
        }
    }
    @else if breakpointIsValid($breakpoint-min) and breakpointIsValid($breakpoint-max){
        @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max){
            @content;
        }
    }
    @else{
        @error 'No value could be retrieved from "#{$breakpoint-min}". It is either not defined in the map, or it is an invalid number of px, em, or rem.';
    }
}

/// @name Buttons
/// @author Iván López Luna [Re]
/// @access public
/// @since Resilient 3.3.0
@mixin button-flat($color: $c-primary, $font-color: white, $isOnMap: false){
    width: auto;
    padding: 0.75em 1em;
    border-radius: 2em;
    display: inline-block;
    font-family: $font-subtitle;
    font-size: 1rem;
    font-weight: $font-regular;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    background-color: transparent;
    transition: color 0.2s, background-color 0.2s !important;
    @if $isOnMap != true{
        @if $color != null{
            color: $font-color;
            background-color: $color;
            &:hover,
            &:focus{
                text-decoration: none;
            }
            &:hover{
                background-color: darken($color, 10);
            }
        }
    }
}
@mixin button-lineal($color: $c-primary, $font-color: $color, $isOnMap: false){
    width: auto;
    padding: 0.5em 2em;
    border-width: 1px;
    border-style: solid;
    border-radius: 2em;
    display: inline-block;
    font-family: $font-body;
    font-size: 1rem;
    font-weight: $font-regular;
    line-height: 1.2;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    text-align: center;
    background-color: transparent;
    transition: border-color 0.2s, color 0.2s, box-shadow 0.2s;
    @if $isOnMap != true{
        @if $color != null{
            border-color: $color;
            color: $font-color;
            box-shadow: inset 0 0 0 $color,
                        inset 0 0 0 $color;
            &:hover,
            &:focus{
                text-decoration: none;
            }
            &:hover{
                color: white;
                box-shadow: inset 300px 0 0 $color,
                            inset -300px 0 0 $color;
            }
        }
    }
}
@mixin text-link($color: $c-primary, $isOnMap: false) {
    position: relative;
    display: inline-block;
    font-size: 1em;
    @if $isOnMap != true {
        @if $color != null {
            color: $color;
        }
    }
    &:hover,
    &:focus {
        color: $color;
        text-decoration: none;
        &::after{
            width: 85%;
        }
    }
    &.disabled {
        color: $c-dusty-gray;
        @include mediaTo(md) {
            font-size: rem(12);
        }
    }
    &::after{
        content: '';
        width: 50%;
        height: 1px;
        margin-top: 0.25em;
        position: absolute;
        right: 0;
        display: block;
        background-color: currentColor;
        transition: width 0.2s ease-out;
        pointer-events: none;
    }
    svg {
        @include sizeProportion($dW: 1);
    }
}