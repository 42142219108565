/*--------------------Typographies--------------------*/
// Titles
@import url('https://fonts.googleapis.com/css?family=Rokkitt:400,700');
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Lexend+Deca&family=Manrope:wght@300&family=Montserrat:wght@300;400;500&display=swap');

// Body text
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:100,200,300,400,500,600,700,800,900');

// Numbers (if it is necessary)
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');