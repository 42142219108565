/*--------------------[Re]set │ Iván Luna [Re]--------------------*/
*,
*:after,
*:before{
	box-sizing: border-box;
	padding: 0;
	border: 0;
	margin: 0;
	outline: 0;
	marker-offset: 0;

	font-family: inherit;
	font-weight: inherit;
	font-style: inherit;

	color: inherit;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main{
	display: block;
}
ul, ol{
	list-style: none;
	margin-bottom: 0;
}
table{
	border-collapse: collapse;
	border-spacing: 0;
}

/*--------------------General Base--------------------*/
html{
	font-size: 90%;
	@media screen and (min-width: $screen-md-min){
		font-size: 100%;
	}
}
body{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
	font-family: $font-body;
	@include font-size;
    font-weight: $font-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	line-height: 1.3;
    color: $c-text;
    // background: white url('#{$image-path}images/bodyBack.png');
}
h1, h2, h3, h4, h5, h6{
	font-family: $font-subtitle;
	font-weight: $font-light;
	color: inherit;
	position: relative;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	line-height: 1.1;
	strong{
		font-weight: $font-x-bold;
		color: inherit;
	}
}
h1{
	@include font-size(2.1);
	@media screen and (min-width: $screen-lg-min){
		@include font-size(2.5);
	}
}
h2{
	@include font-size(1.9);
	@media screen and (min-width: $screen-lg-min){
		@include font-size(2.3);
	}
}
h3{
	@include font-size(1.7);
	@media screen and (min-width: $screen-lg-min){
		@include font-size(2);
	}
}
h4{
	@include font-size(1.5);
	@media screen and (min-width: $screen-lg-min){
		@include font-size(2);
	}
}
h5{
	@include font-size(1.3);
	@media screen and (min-width: $screen-lg-min){
		@include font-size(1.5);
	}
}
h6{
	@include font-size(1.1);
	@media only screen and (min-width: $screen-lg-min) {
		@include font-size(1.3);
	}
}
p{
	margin-bottom: 10px;
	&:only-child,
	&:last-child{
		margin-bottom: 0;
	}
}
a{
	text-decoration: none;
	transition: color .2s ease, background .2s ease;
	&:hover,
	&:focus{
		outline: none;
		color: inherit;
		text-decoration: none;
	}
}
b,
strong,
mark{
	font-weight: $font-bold;
	color: inherit;
	background: none;
}
i,
em{
	font-weight: $font-regular;
	font-style: normal;
}
em{
	color: inherit;
}
blockquote{
	margin: 0.75em 2em;
	@include font-size(1.2);
}
img{
	display: inline-block;
	max-width: 100%;
}
form{
	button{
		background-color: transparent;
		cursor: pointer;
	}
}