/*--------------------[Re] - New--------------------*/
.no-boxsizing .re-novar,
.no-csstransitions .re-novar{
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999999999;
	@include font-size(1.3);
	text-align: center;
	color: $c-text;
	background: $c-lightgray-l-1;
	div{
		padding: 20% 10% 0 10%;
	}
	strong{
		@include font-size(1.7);
		color: $c-text-d10;
	}
	a{
		color: $c-link-default;
	}
}