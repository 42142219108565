/*--------------------General Layout - Nav--------------------*/
.site-nav{
    width: auto;
    min-width: auto;
	left: 0;
}

/*--------------------Mobile - Iván Luna [Re]--------------------*/
@media only screen and (max-width: $screen-md-max) {
	.site-nav{
		min-height: 0;
        z-index: $z-nav-mobile;
        font-size: 0.9rem;
		background-color: transparent;
	}

	/*-----General settings-----*/
	ul.re-ul-principal{
        @include flexbox();
		a{
            @include padding-h(7);
			color: $c-text;
        }
        li:last-child a{
            padding-right: 0;
        }
	}
}

/*--------------------Desktop - Iván Luna [Re]--------------------*/
@media screen and (min-width: $screen-lg-min){
	.site-nav{
		min-height: 50px;
		z-index: $z-nav-desktop;
		background-color: transparent;
	}
	// Para evitar estilos del menu responsive
	// Para evitar que el body crezca al dar click sobre los items
	.dl-submenu.dl-animate-in-1,
	.dl-submenu.dl-animate-in-2,
	.dl-submenu.dl-animate-in-3,
	.dl-submenu.dl-animate-in-4,
	.dl-submenu.dl-animate-in-5,
	.dl-trigger{
		display: none !important;
		position: absolute;
	}

	/*-----General settings-----*/
	ul.re-ul-principal{
		font-weight: $font-regular;
		text-align: right;
		// Hide & Show Level 2
		> li{
			> ul{
				// display: none;
				border-top: 2px solid $c-secondary;
				opacity: 0;
				transform: scaleY(0);
				transform-origin: 0 0 0;
				transition: transform 0.25s ease;
			}
			&:hover > ul{
				// display: block;
				opacity: 1;
				transform: scaleY(1);
			}
		}

		// Level 1
		> li{
			width: auto;
			display: inline-block;
			margin-left: -0.225em;
			// Anchors in first level
			> a{
				display: block;
				min-height: 50px;
				position: relative;
				@include padding-h(10);
				line-height: 50px;
				color: $c-text;
			}
			&:hover > a{
				color: $c-secondary;
			}
		}

		ul{
			// Anchors from second level
			a{
				display: block;
				@include padding-h(10);
				color: $c-text-l40;
				&:hover{
					color: $c-secondary;
				}
			}
		}

		/*-----Menu Item - Expand-----*/
		// Level 1 - Items
		li.expand{
			position: relative;
			// Hide & Show Level 3-4-5...
			> ul{
				li{
					> ul{
						// display: none;
						transform: scaleY(0);
						transform-origin: 0 0 0;
						transition: all 0.25s ease;
					}
					&:hover > ul{
						// display: block;
						transform: scaleY(1);
					}
				}
				// Indicadores de Subnivel
				a:not(:last-child):after {
					content: '\e258';
					position: absolute;
					top: 50%;
					right: 5px;
					margin-top: -5px;
					font-family: 'Glyphicons Halflings';
					@include font-size(.7);
					line-height: 1;
				}
			}
			// Level 2
			ul{
				width: 110%;
				position: absolute;
				top: 100%;
				left: -5%;
				text-align: center;
				li{
					position: relative;
					border-bottom: 1px solid $c-lightgray;
					&:last-child{
						border-bottom: none;
					}
					&:hover > a{
						color: $c-secondary;
					}
					a{
						@include padding-v(10);
						color: $c-text-l40;
						background-color: rgba($c-primary,1);
					}
					// Level 3
					ul{
						width: 100%;
						top: 0;
						left: 100%;
						li{
							border-left: 1px solid $c-lightgray;
							a{
								color: $c-text-l40;
								background-color: rgba($c-primary,1);
							}
							// Level 4
							ul li a{
								background-color: rgba($c-primary,1);
							}
						}
					}
				}
			}
		}

		/*-----Menu Item - Megamenu-----*/
		// Level 1 - Items
		li.mega-menu{
			position: static;
			// Level 2 [Categories]
			> ul{
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				@include font-size(.8);
				background-color: rgba($c-primary,1);
				> li{
					width: 20%;
					float: left;
					@include padding-h(10);
					border-right: 1px solid $c-lightgray;
					&:last-child{
						border-right: none;
					}
					> a{
						@include padding-v(10);
						border-bottom: 1px solid $c-lightgray-d10;
						text-transform: uppercase;
					}
					img{
						width: 75%;
						display: block;
						padding: 3px;
						border: 1px solid white;
						border-radius: 5px;
						margin: $re-gutter auto $re-gutter*0.5;
					}
					// Level 3
					ul{
						// display: block;
						position: relative;
						margin-bottom: $re-gutter*0.5;
						li{
							border-right: none;
							a{
								@include padding-v(5);
							}
						}
					}
				}
			}
		}

		li.dl-back{
			display: none;
		}
	}
}
