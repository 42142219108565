/*--------------------General Layout - Header--------------------*/
.site-header{
    min-height: $header-height-bs;
    border-bottom: 1px solid $c-light-gray;
    margin-bottom: 10px;
    @include flexbox($ai: center);
	// position: fixed;
	top: 0;
	left: 0;
	z-index: $z-header;
	background-color: white;
	// box-shadow: 0 0 5px rgba(black,0.25);
	transition: background-color $animate-time;
	@media screen and (min-width: $screen-md-min) {
		min-height: $header-height-md;
	}
	@media screen and (min-width: $screen-lg-min) {
		min-height: $header-height-lg;
    }
    &__content{
        @include flexbox($jc: space-between, $ai: center);
    }
}
// Change header
.page-scroll-on .site-header{
	background-color: rgba($c-primary,0.95);
}