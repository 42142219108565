/*--------------------Button Wrapper--------------------*/
.button-wrapper{
	display: block;
	margin-top: $re-gutter;
}

/*--------------------General Settings to Buttons--------------------*/
.button-lineal,
.button-flat{
	cursor: pointer;
	&:hover,
	&:focus{
		text-decoration: none;
	}
	// To icons
	svg{
		margin-right: 0.5em;
	}
}

/*--------------------Button Flat--------------------*/
.button-flat{
    @include button-flat($isOnMap: true);
	@each $color,$value in $color-palette-map {
		&.#{$color} {
			color: map-get($value,font);
			background-color: map-get($value,color);
		}
		&.#{$color}:hover {
			background-color: darken(map-get($value,color),10);
		}
	}
}

/*--------------------Button Lineal--------------------*/
.button-lineal{
    @include button-lineal($isOnMap: true);
	@each $color,$value in $color-palette-map {
		&.#{$color} {
			border-color: map-get($value,color);
			color: map-get($value,color);
			box-shadow: inset 0 0 0 map-get($value,color),
						inset 0 0 0 map-get($value,color);
		}
		&.#{$color}:hover{
			color: map-get($value,font);
			box-shadow: inset 300px 0 0 map-get($value,color),
						inset -300px 0 0 map-get($value,color);
		}
	}
}