/*--------------------General Layout - Footer--------------------*/
footer{
	min-height: 100px;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
	z-index: $z-footer;
	text-align: center;
	background-color: $c-primary;
	// box-shadow: 0 0 10px rgba(black,0.1);
}
// General
.footer-title{
	@include margin-v(0);
	font-family: $font-subtitle;
	@include font-size(1.75);
}
.footer-block{
	@include font-size(0.85);
	@media screen and (max-width: $screen-md-max){
		margin-bottom: 1em;
	}
}
.footer-list{
	li{
		margin-bottom: 0.75em;
	}
	a{
		&:hover{
			color: inherit;
		}
	}
}
/*-----Principal Footer-----*/
.principal-footer{
	@include padding-v(30);
}


footer{
    color: $c-light-gray;
	a:hover{
		color: $c-secondary;
	}
}
.signo{
    display: block;
    margin: .5% auto 0 auto;
    animation: signo 4s infinite ease-in-out alternate;
}
@keyframes signo{
	from	{transform: rotate(0deg)}
	to		{transform: rotate(360deg)}
}

input{
	color: $c-text;
}