/*--------------------Miscellaneous--------------------*/
#root{
    min-height: 100vh;
    @include flexbox($fd: column);
    > main{
        margin-bottom: auto;
    }
}
.Main--ProjectDetail,
.Main--AboutMe{
    margin-top: auto;
}
.AboutMe{
    @include padding-v(1,1,1em);
    font-family: $font-subtitle;
    font-size: 1.5rem;
    &-Name{
        font-family: $font-title;
        color: $c-primary;
    }
    &-Role{
        span{
            color: $c-dusty-gray;
        }
    }
    &-Resume{
        margin: 2.5em auto 0;
        font-family: $font-body;
        font-size: 0.9rem;
        line-height: 1.5;
        @include mediaFrom(md){
            max-width: 60rem;
        }
    }
    &-Contact{
        line-height: 1;
    }
}

.ProjectList--Featured{
    margin-bottom: 2em;
    .button-flat{
        margin-top: 2em;
    }
}